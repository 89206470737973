/**
 * @name: 商城管理-banner轮播图管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-banner轮播图管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IBanner, IBannerParams } from "./types";

export const bannerPageApi = (params: IBannerParams) => get<IPageRes<IBanner[]>>("/admin/banner/query", params)

export const bannerDetailApi = (id: string) => get<IBanner>("/admin/banner/detail/" + id)

export const bannerCreateApi = (data: Partial<IBanner>) => postJ("/admin/banner/create", data)

export const bannerModifyApi = (data: Partial<IBanner>) => postJ("/admin/banner/modify", data)
