/**
 * @name: 商品管理-第三方商品库接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商品管理-第三方商品库接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IProduct, IProductParams, IProductSku, IProductDropParams } from "./types";

export const productPageApi = (params: IProductParams) => get<IPageRes<IProduct[]>>("/admin/product/query", params)

export const productNewPageApi = (params: IProductParams) => get<IPageRes<IProduct[]>>('/admin/product/query/third', params)

export const productDetailApi = (id: string) => get<IProduct>("/admin/product/detail/" + id)

export const productAddShelvesApi = (data: {ids: string[]}) => postJ("/admin/product/batchAddToShelves", data)

export const productPullApi = () => postJ("/admin/product/pull")

export const productExportApi = (params: IProductParams) => get("/admin/product/third/export", params, 'blob')

export const productModifyApi = (data: Partial<IProduct>) => postJ("/admin/product/modify", data)

export const productSkuDetailApi = (id: string) => get<IProductSku>("/admin/productsku/detail/" + id)

export const productDropApi = (params: IProductDropParams) => get<IProduct[]>("/admin/product/dropDown", params)
